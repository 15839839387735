import React from "react";
import styled from "styled-components";
import colors from "../../colors";
import { useEffect } from "react";
import Man from "../../Img/services.png";
import Slick from "./Slick";
import AOS from "aos";
import "aos/dist/aos.css";

const TwoManFlex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  background-color: ${colors.Black};
  height: 260px;
  position: relative;
`;

const DescServ = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 900;
  color: ${colors.white};
  font-size: 35px;
  letter-spacing: 9px;

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

const TwoMen = styled.img`
  height: 200px;

  @media (max-width: 600px) {
    height: 100px;
  }

  @media (max-width: 400px) {
    height: 90px;
    overflow: hidden;
  }
`;

const Slicker = styled.div`
  position: relative;
  z-index: 2;
`;

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
      <Slicker>
        <TwoManFlex className="TwoManFlex">
          <TwoMen className="TwoMen" src={Man} />
          <DescServ className="DescServ">Služby</DescServ>
          <TwoMen src={Man} />
        </TwoManFlex>
        <Slick />
      </Slicker>
    </>
  );
};

export default Services;
