import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PoznejteMe from "./components/About/PoznejteMe";
import Hero from "./components/Hero/Hero";
import Galerie from "./components/Galerie/Galerie";
import Services from "./components/Services/Services";
import Form from "./components/MailerForm/Form";
import { useReservation } from "./props/ReservationContext";
import styled from "styled-components";
import Map from "./components/Map/Map";
import End from "./components/End/End";
import Link from "./components/DesignedBy/Link";
import CookieBanner from "./CookieBanner/CookieBanner";
import { useContext } from "react";
import {
  HeroRefContext,
  AboutRefContext,
  ServicesRefContext,
  GalleryRefContext,
} from "./props/Ref";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GDPR from "./components/GDPR";
// import SnowCanvas from "./SnowCanvas"

const ShadowDiv = styled.div`
  opacity: ${({ isHiden }) => (isHiden ? "1" : "0.3")};
`;
const App = () => {
  const { isHiden } = useReservation();
  const heroRef = useContext(HeroRefContext);
  const aboutRef = useContext(AboutRefContext);
  const servicesRef = useContext(ServicesRefContext);
  const galleryRef = useContext(GalleryRefContext);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/gdpr" element={<GDPR />} />
          <Route
            path="/"
            element={
              <>
                <CookieBanner></CookieBanner>
                <Form />
                <ShadowDiv className="shadowDiv" isHiden={isHiden}>
                  {/* <SnowCanvas /> */}
                  <Header />
                  <div ref={heroRef}>
                    <Hero />
                  </div>
                  <div ref={aboutRef}>
                    <PoznejteMe />
                  </div>
                  <div ref={servicesRef}>
                    <Services/>
                  </div>
                  <div ref={galleryRef}>
                    <Galerie />
                  </div>
                  <Map />
                  <End />
                  <Footer />
                  <Link />
                </ShadowDiv>
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
